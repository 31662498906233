//TOASTR
import toastr from 'toastr';
window.toastr = toastr;
import "./plugins/toastr/toastr.scss";

//FANCYBOX
import '@fancyapps/fancybox';
import "./plugins/fancybox/core.scss";
import "./plugins/fancybox/fullscreen.scss";
import "./plugins/fancybox/share.scss";
import "./plugins/fancybox/slideshow.scss";
import "./plugins/fancybox/thumbs.scss";

///FONTAWESOME 5
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free';

//OWLCAROUSEL
import 'owl.carousel';
import "./plugins/owlcarousel/src/scss/owl.carousel.scss";
import "./plugins/owlcarousel/src/scss/_theme.default.scss";

//AOS

import '../node_modules/aos/dist/aos.css';